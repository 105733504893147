import React from "react"

export const OpenInApp = ({ type, slug }) => {
    const webLink = `https://tictic.app/${type}/${slug}`
    const fdLink = `https://tictic.page.link/?link=${webLink}&apn=co.tictic.tictic&isi=1437805697&ibi=co.tictic.tictic`

    if (typeof window === `undefined`) {
        return(<></>);
    }else if (window.innerWidth <= 768) {
        return (
            <div style={{
                width: "100%",
                textAlign: "center"
            }}>
                <a href={fdLink} style={{
                    padding: "1em",
                    textAlign: "center",
                    display: "inline-block",
                    textDecoration: "none",
                }}>Open in App</a>
            </div>
        )
    }
    return (
        <>
        </>
    );
}