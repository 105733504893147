import * as React from "react"
import { graphql } from "gatsby"
import { ListComponent } from "../components/list"

const ListPage = ({ data }) => {
  return <ListComponent list={data.tictic.list} />
}

export const query = graphql`
  query GetList($id: ID!) {
    tictic {
      list: getList(listId: $id) {
        listId
        slug
        title
        description
        itemsCount
        image {
          thumbUrl
          dynamicUrl
        }
        items {
          itemId
          slug
          name
          cardImage {
            thumbUrl
            dynamicUrl
          }
        }
      }
    }
  }
`
export default ListPage
