import Layout from '../layout';
import { rhythm } from '../../utils/typography'
import React from "react"
import { graphql } from "gatsby"
import ItemTile from '../item-tile';
import parse from 'html-react-parser';
import { OpenInApp } from '../mobile';

export const listFragment = graphql`
  fragment listFragment on TICTIC_List {
            listId
            slug
            title
            description
            image {
              thumbUrl
              dynamicUrl
            }
            items {
              itemId
              slug
              name
              cardImage {
                thumbUrl
                dynamicUrl
              }
            }
  }
`

export const ListComponent = ({ list }) => {
  const seo = {
    metaTitle: list.title,
    metaDescription: list.description,
    shareImage: list.thumbUrl,
    article: false,
  }


  // let name = list.title ?? '';
  // let nameLocal = item.nameLocal;
  // let locations = item.locations?.map(l => [l.nameDisplay, l.country].join(', ')).join('\n') ?? '';
  // let description = item.description ?? '';
  // let imageUrl = item.cardImage?.cardUrl ?? 'https://via.placeholder.com/300x200';

  // const webLink = `https://tictic.app/item/${item.itemId}`;
  // const fdLink = `https://tictic.page.link/?link=${webLink}&apn=co.tictic.tictic&isi=1437805697&ibi=co.tictic.tictic`;

  return (
    <Layout seo={seo}>
                  <div style={{margin: '85px 0 0 0', padding: '12px'}}>

      <article>
        <header style={{ flexFlow: `column`, textAlign: `center` }}>
          <h3
            style={{
              marginTop: rhythm(1),
              marginBottom: rhythm(1),
            }}
          >
            {list.title}
          </h3>
          <img
            src={
              list.image?.dynamicUrl
                ?.replace("[WIDTH]", 400)
                ?.replace("[HEIGHT]", 265) || ""
            }
            alt={list.title}
            style={{
              borderRadius: `3%`,
              maxHeight: `40vh`,
              width: '400px',
              height: '265px'
            }}
          />

          <p>{parse(list.description ?? "")}</p>
        </header>
      </article>
      <hr
        style={{
          marginTop: rhythm(1),
          marginBottom: rhythm(2),
        }}
      />
      <ul className="grid-layout">
        {list.items.map((value, index) => {
          return <ItemTile value={value} index={index} key={index}></ItemTile>
        })}
      </ul>
      <OpenInApp type='list' slug={list.slug}/>
      </div>
    </Layout>
  )
}

export default ListComponent
